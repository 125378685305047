import React from "react";

type ArrowRightProps = React.SVGProps<SVGSVGElement>;

const ArrowRight: React.FC<ArrowRightProps> = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="tablet:size-6 size-5"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.126 23.7998L27.838 16.5688C28.2356 16.1404 28.2356 15.4786 27.838 15.0503L21.126 7.81922L20.3656 7L18.7246 8.51848L19.485 9.3377L24.4555 14.6926H6.11884H3V16.9265H6.11884H24.4555L19.485 22.2813L18.7246 23.1006L20.3656 24.619L21.126 23.7998Z"
      />
    </svg>
  );
};

export default ArrowRight;
